import React from "react"
import { useStaticQuery, Link, graphql } from "gatsby"

import Image from "../image"
import styles from "./moreArticles.module.scss"

const MoreArticles = props => {
  const data = useStaticQuery(
    graphql`
      query {
        craft {
          articles: entries(section: "athenaArticles") {
            id
            title
            uri
            ... on Craft_athenaArticles_athenaArticleCustom_Entry {
              articleSubTitle
              issue {
                id
              }
              coverImages {
                ... on Craft_coverImages_BlockType {
                  ratio_4x3 {
                    id
                    url
                    width
                    height
                    ... on Craft_zjContent_Asset {
                      altText
                    }
                  }
                  ratio_3x4 {
                    id
                    url
                    width
                    height
                    ... on Craft_zjContent_Asset {
                      altText
                    }
                  }
                  ratio_1x1 {
                    id
                    url
                    width
                    height
                    ... on Craft_zjContent_Asset {
                      altText
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  const articleId = props.id
  const issueId = props.issue
  // Filter all articles down to only articles from this issue excluding
  // the current article
  const issueArticles = data.craft.articles.filter(
    article => article.issue[0].id === issueId && article.id !== articleId
  )

  return (
    <nav className={styles.more}>
      <div className="wrapper">
        <div className="mb-8">
          <div className={styles.heading}>
            <h2 className="a-h-2">More from this issue</h2>
          </div>
          <div className={styles.articles}>
            <div className="columns is-mobile is-tablet is-multiline">
              <div
                className={`column is-12-mobile is-6-tablet is-4-desktop ${styles.article}`}
              >
                <Link className="img-tile" to={`/${issueArticles[0].uri}`}>
                  <div className={`mb-4 img-tile-image`}>
                  
                  <Image
                      width={issueArticles[0].coverImages[0].ratio_4x3[0].width}
                      height={
                        issueArticles[0].coverImages[0].ratio_4x3[0].height
                      }
                      // Excluding the gutters to account for scaling on hover with CSS
                      sizes="(min-width: 1344px) 690px, (min-width: 768px) calc(50vw), calc(100vw)"
                      url={issueArticles[0].coverImages[0].ratio_4x3[0].url}
                      alt={issueArticles[0].coverImages[0].ratio_4x3[0].altText}
                    />
                  
                  </div>
                  
                  <p className="a-h-6">{issueArticles[0].articleSubTitle}</p>
                  <h3 className="a-h-3 italic">
                    <span className="img-tile-title-underline">
                      {issueArticles[0].title}
                    </span>
                  </h3>
                </Link>
              </div>
              <div
                className={`column is-12-mobile is-6-tablet is-4-desktop ${styles.article}`}
              >
                <Link className="img-tile" to={`/${issueArticles[1].uri}`}>
                  <div className={`mb-4 img-tile-image`}>
                    <Image
                      width={issueArticles[1].coverImages[0].ratio_3x4[0].width}
                      height={
                        issueArticles[1].coverImages[0].ratio_3x4[0].height
                      }
                      // Excluding the gutters to account for scaling on hover with CSS
                      sizes="(min-width: 1344px) 690px, (min-width: 768px) calc(50vw), calc(100vw)"
                      url={issueArticles[1].coverImages[0].ratio_3x4[0].url}
                      alt={issueArticles[1].coverImages[0].ratio_3x4[0].altText}
                    />
                  </div>
                  
                  <p className="a-h-6">{issueArticles[1].articleSubTitle}</p>
                  
                  <h3 className="a-h-3 italic">
                    <span className="img-tile-title-underline">
                      {issueArticles[1].title}
                    </span>
                  </h3>
                </Link>
              </div>
              {issueArticles[2] && 
              <div
                className={`column is-12-mobile is-6-tablet is-4-desktop ${styles.article}`}
              >
                <Link className="img-tile" to={`/${issueArticles[2].uri}`}>
                  <div className={`mb-4 img-tile-image`}>
                    <Image
                      width={issueArticles[2].coverImages[0].ratio_1x1[0].width}
                      height={
                        issueArticles[2].coverImages[0].ratio_1x1[0].height
                      }
                      // Excluding the gutters to account for scaling on hover with CSS
                      sizes="(min-width: 1344px) 690px, (min-width: 768px) calc(50vw), calc(100vw)"
                      url={issueArticles[2].coverImages[0].ratio_1x1[0].url}
                      alt={issueArticles[2].coverImages[0].ratio_1x1[0].altText}
                    />
                  </div>
                  <p className="a-h-6">{issueArticles[2].articleSubTitle}</p>
                  <h3 className="a-h-3 italic">
                    <span className="img-tile-title-underline">
                      {issueArticles[2].title}
                    </span>
                  </h3>
                </Link>
              </div>
              }
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default MoreArticles
